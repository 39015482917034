import * as React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '@/layouts/Default'
import ArticleHero from '@/components/ArticleHero'
import ArticleTextBlock from '@/components/ArticleTextBlock'

const ArticleContainer = styled.div`
  width: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StaticPage = ({ data, pageContext: { requireAgeGate, pagePath, marketCode: pageMarketCode } }) => {
  const content = data.drupal.nodeById

  function handleClick () {
    // console.log(Optanon.TriggerGoogleAnalyticsEvent)
    // Optanon.TriggerGoogleAnalyticsEvent('OneTrust Cookie Consent', 'Banner Open Preferences')
  }

  return (
    <Layout entityBundle='article' path={ pagePath } requireAgeGate={ requireAgeGate } plainHeader pageType='Legal' pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>{data.drupal.nodeById?.fieldStaticPageMetaTags.entity.fieldMetaTitle}</title>
        <meta name='description' content={ data.drupal.nodeById?.fieldStaticPageMetaTags.entity.fieldMetaDescription } />
      </Helmet>
      <ArticleContainer>
        <ArticleHero img={ content?.fieldImage?.url } hideBackButton />
        <ArticleTextBlock
          title={ content?.fieldStaticPageFrontendTitle }
          textOne={ content?.body?.value }
          hideShareButton
          hideBackButton
        />
      </ArticleContainer>
    </Layout>
  )
}

export default StaticPage

export const pageQuery = graphql`
query StaticPageQuery($nid: String!) {
  drupal {
    nodeById(id: $nid) {
      title
      ... on Drupal_NodeArticle {
        fieldImage {
          alt
          url
        }
        fieldStaticPageMetaTags {
          entity {
            ... on Drupal_ParagraphMetaTags {
              fieldMetaTitle
              fieldMetaDescription
            }
          }
        }
        fieldStaticPageFrontendTitle
        body {
          value
        }
      }
    }
  }
}
`
